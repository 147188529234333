import BaseModel from '../models/BaseModel';

export enum ModelType {
    // Auth
    TENANTS = 'tenants',
    USERS = 'users',
    TENANT_USERS = 'tenant-users',
    LOGIN_QR_CODES = 'login-qr-codes',
    LOGIN_MAGIC_LINK = 'login-magic-link',

    // Contact
    CONTACTS = 'contacts',

    // CRM
    LEADS = 'leads',
    SOURCES = 'sources',

    // Task
    TASKS = 'tasks',

    // Inventory
    PRODUCTS = 'products',
    PRODUCT_CATEGORIES = 'product-categories',
    PRODUCT_UNITS = 'product-units',
    PRODUCT_SECTIONS = 'product-sections',

    // Planning
    TIME_ACTIVITIES = 'time-activities',
    TIME_ACTIVITY_GEOLOCATIONS = 'time-activity-geolocations',
    TIME_ACTIVITY_PAUSES = 'time-activity-pauses',
    CALENDAR_EVENTS = 'calendar-events',
    TIME_ACTIVITY_SETTINGS = 'time-activity-settings',

    // Worksite
    WORKSITES = 'worksites',
    MODEL_FILES = 'model-files',
    MODEL_FILE_FOLDERS = 'model-file-folders',
    WORKSITE_PHASES = 'worksite-phases',
    WORKSITE_PROFITABILITY_DOCUMENTS = 'worksite-profitability-documents',
    WORKSITE_PROGRESS_BILLING_LINES = 'worksite-progress-billing-lines',
    WORKSITE_PROGRESS_BILLINGS = 'worksite-progress-billings',
    WORKSITE_PROGRESS_BILLING_FORM = 'worksite-progress-billing_form',
    WORKSITES_CLOSURE_REPORT = 'closure-reports',

    // Invoicing
    EXPENSES = 'expenses',
    VAT_RATES = 'vat-rates',
    INVOICES = 'invoices',
    INVOICE_AUTOSAVES = 'invoice-autosaves',
    INVOICE_SECTIONS = 'invoice-sections',
    INVOICE_LINES = 'invoice-lines',
    INVOICE_FILES = 'invoice-files',
    INVOICES_ANNEX_PDFS = 'invoice-annex-pdfs',
    INVOICE_PAYMENTS = 'invoice-payments',
    INVOICING_NUMBERING_SETTINGS = 'invoicing-numbering-settings',
    INVOICING_DOCUMENTS_SETTINGS = 'invoicing-documents-settings',
    INVOICING_NOTIFICATIONS_SETTINGS = 'invoicing-notifications-settings',
    INVOICING_PAYMENT_REMINDERS_SETTINGS = 'invoicing-payment-reminders-settings',

    // Bankaccount
    BANK_ACCOUNTS = 'bank-accounts',
    BANK_ACCOUNT_TRANSACTIONS = 'bank-account-transactions',
    BANK_ACCOUNT_AUTHORIZATIONS = 'bank-account-authorizations',

    // PDF
    PDFS = 'pdfs',
    PDF_IMAGES = 'pdf-images',

    // OTHER
    NOTIFICATIONS_LOGS = 'notifications-logs',
    WORKFIELDS = 'workfields',
    IMPORTED_FILE = 'files',
    WASTE_CENTER = 'waste-centers',

    //  is duplicate
    MODEL_EVENTS = 'model-events',

    // Added from scaffolding script
    COMPOSED_WORK_LINES = 'composed-work-lines',
    TERMS_OF_SERVICE_ACCEPTATIONS = 'terms-of-service-acceptations',
    TERMS_OF_SERVICES = 'terms-of-services',
    INVOICE_DISCOUNTS = 'invoice-discounts',
    NOTIFICATIONS = 'notifications',
    PRODUCT_IMAGES = 'product-images',
    SETTINGS = 'settings',
    CONNECTIONS = 'connections',
    GLOBAL_SEARCH_ITEMS = 'global-search-items',
    PLANNING_ITEMS = 'planning-items',
    INVOICE_TEMPLATE_SECTION_COLUMNS = 'invoice-template-section-columns',
    INVOICE_TEMPLATE_SECTIONS = 'invoice-template-sections',
    INVOICE_TEMPLATES = 'invoice-templates',
    SUBSCRIPTION_CARD = 'subscription-card',
}

type Relationship<T> = {
    type: T;
    id: string | null;
};

export type ToOneRelationship<T> = {
    data: Relationship<T> | null;
};

export type ToManyRelationship<T> = {
    data: Relationship<T>[];
};

type AnyRelationship<T = any> = ToOneRelationship<T> | ToManyRelationship<T>;

type Relationships = Record<string, AnyRelationship>;

interface Attributes extends Record<string, any> {}
interface Meta extends Record<string, any> {}

interface JsonSchema<A extends Attributes, M extends Meta, R extends Relationships> {
    data: {
        type: ModelType;
        id?: string;
        attributes?: Partial<A>;
        meta?: Partial<M>;
        relationships?: Partial<R>;
    };
}

interface SchemaConstructor<A extends Attributes, M extends Meta> {
    attributes?: Partial<A>;
    meta?: Partial<M>;
    id?: string;
}

type GetTypeFromRelation<R extends AnyRelationship> = R extends AnyRelationship<infer T> ? T : unknown;

type ExcludedRelations<T, R> = {
    [K in keyof T]: T[K] extends R ? never : K;
}[keyof T];

type ExcludeRelation<T, R> = Pick<T, ExcludedRelations<T, R>>;
type ExcludeToManyRelations<T> = ExcludeRelation<T, ToManyRelationship<any>>;
type ExcludeToOneRelations<T> = ExcludeRelation<T, ToOneRelationship<any>>;

export { Attributes, Meta, Relationships, JsonSchema };

export abstract class BaseSchema<A extends Attributes, M extends Meta, R extends Relationships> {
    protected abstract type: ModelType;

    attributes?: Attributes;
    meta?: Meta;
    id?: string;
    relationships?: Partial<R>;

    constructor({ attributes, meta, id }: SchemaConstructor<A, M>) {
        this.id = id;
        this.attributes = attributes;
        this.meta = meta;
    }

    static make(schema: SchemaConstructor<Attributes, Meta>): BaseSchema<Attributes, Meta, Relationships> {
        return new this(schema);
    }

    static makeFromModel(model: BaseModel): BaseSchema<Attributes, Meta, Relationships> {
        return new this({
            id: model.id,
            attributes: model.attributes,
            meta: model.meta,
        });
    }

    addToOneRelationship<K extends keyof ExcludeToManyRelations<R>>(key: K, type: GetTypeFromRelation<R[K]>, id: string | null): void {
        if (!this.relationships) {
            this.relationships = {};
        }

        if (id == null) {
            this.relationships[key] = { data: null } as R[K];
            return;
        }

        const relation: ToOneRelationship<GetTypeFromRelation<R[K]>> = {
            data: { type, id },
        };

        this.relationships[key] = relation as R[K];
    }

    addEmptyToManyRelationship<K extends keyof ExcludeToOneRelations<R>>(key: K): void {
        if (!this.relationships) {
            this.relationships = {};
        }

        const relation: ToManyRelationship<GetTypeFromRelation<R[K]>> = {
            data: [],
        };

        this.relationships[key] = relation as R[K];
    }

    addToManyRelationships<K extends keyof ExcludeToOneRelations<R>>(key: K, type: GetTypeFromRelation<R[K]>, ids: string[]): void {
        for (const id of ids) {
            this.addToManyRelationship(key, type, id);
        }
    }

    addToManyRelationship<K extends keyof ExcludeToOneRelations<R>>(key: K, type: GetTypeFromRelation<R[K]>, id: string): void {
        if (!this.relationships) {
            this.relationships = {};
        }

        if (this.relationships[key]) {
            const relationship = this.relationships[key] as ToManyRelationship<GetTypeFromRelation<R[K]>>;
            relationship.data.push({ type, id });
            return;
        }

        const relation: ToManyRelationship<GetTypeFromRelation<R[K]>> = {
            data: [{ type, id }],
        };

        this.relationships[key] = relation as R[K];
    }

    json(): JsonSchema<A, M, R> {
        const json: JsonSchema<A, M, R>['data'] = {
            type: this.type,
        };

        if (this.attributes) {
            json.attributes = this.attributes;
        }

        if (this.id) {
            json.id = this.id;
        }

        if (this.relationships) {
            json.relationships = this.relationships;
        }

        return {
            data: json,
        };
    }
}
