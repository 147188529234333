import Expense from '../models/Expense';
import BaseCollection from '@/app/base/collections/BaseCollection';
import BaseModel from '~~/app/base/models/BaseModel';
import moment from '~~/utils/moment';
import { ModelType } from '~~/app/base/schemas/BaseSchema';
import User from '~~/app/auth/models/User';
import ExpenseStatus from '../enums/ExpenseStatus';

export default class ExpenseCollection extends BaseCollection<Expense> {
    model: ModelType = ModelType.EXPENSES;

    byStatus(status: string): Expense[] {
        return this.filter((Expense: Expense) => Expense.hasStatus(status));
    }
}
