import UserCollection from '../collections/UserCollection';
import { TenantAuthedMeta, TenantContactInformations, TenantWebsocketMeta } from '../schemas/TenantSchema';
import { TenantInternalStatus } from '@/app/auth/enums/TenantInternalStatus';
import User from '@/app/auth/models/User';
import { MODE, Mode } from '@/app/enums/tenant/Mode';
import Workfield from '@/app/common/models/Workfield';
import BaseModel from '~~/app/base/models/BaseModel';
import { ModelType } from '~~/app/base/schemas/BaseSchema';

export default class Tenant extends BaseModel {
    type: ModelType = ModelType.TENANTS;
    static type: ModelType = ModelType.TENANTS;
    prefix = 'tenancy:tenant' as const;
    static prefix = 'tenancy:tenant' as const;

    get workfield(): Workfield | null {
        return this.relationships?.workfield?.data ?? null;
    }

    get users(): UserCollection | null {
        return this.relationships?.tenantUsers?.data ?? null;
    }

    get activeUsers(): User[] | null {
        return this.users?.filter((user) => user.isActive) ?? null;
    }

    hasMultipleUsers(): boolean {
        return this.users?.count() > 1;
    }

    isAlone(): boolean {
        return this.users?.count() === 1;
    }

    get name(): string {
        return this.attributes?.name;
    }

    set name(value: string) {
        this.attributes.name = value;
    }

    get locale(): string {
        return this.attributes?.locale;
    }

    set locale(value: string) {
        this.attributes.locale = value;
    }

    get logoUrl(): string {
        return this.attributes?.logoUrl;
    }

    set logoUrl(value: string) {
        this.attributes.logoUrl = value;
    }

    get vatNumber(): string {
        return this.attributes?.vatNumber;
    }

    get siret(): string {
        return this.attributes?.siret;
    }

    set vatNumber(value: string) {
        this.attributes.vatNumber = value;
    }

    get address(): Address {
        return this.attributes?.address;
    }

    set address(value: Address) {
        this.attributes.address = value;
    }

    get streetBoxNumber(): number | null {
        return this.attributes?.address.streetBoxNumber;
    }

    get billingAddress(): Address {
        return this.attributes?.billingAddress;
    }

    set billingAddress(value: Address) {
        this.attributes.billingAddress = value;
    }

    get billingStreetBoxNumber(): number | null {
        return this.attributes?.billingAddress.streetBoxNumber;
    }

    get subscriptionBillingCycle(): string | null {
        return this.attributes?.subscriptionBillingCycle;
    }

    get billingCountry(): string {
        return this.attributes?.billingCountry;
    }

    get onboardingCompleted(): boolean {
        return this.attributes?.onboardingCompleted;
    }

    isFrench(): boolean {
        return this.billingCountry === 'fr';
    }

    isBelgian(): boolean {
        return this.billingCountry === 'be';
    }

    get billingLocale(): string {
        return this.attributes?.billingLocale;
    }

    set billingLocale(value: string) {
        this.attributes.billingLocale = value;
    }

    get contactInformations(): TenantContactInformations {
        return this.attributes?.contactInformations;
    }

    set contactInformations(value: TenantContactInformations) {
        this.attributes.contactInformations = value;
    }

    get subscriptionId(): string {
        return this.attributes?.subscriptionId;
    }

    get subscriptionStatus(): string {
        return this.attributes?.subscriptionStatus;
    }

    get subscriptionPlan(): string {
        return this.attributes?.subscriptionPlan;
    }

    get readOnly(): boolean {
        return this.attributes?.readOnly;
    }

    get authedMeta(): TenantAuthedMeta {
        return this.meta?.authed ?? {};
    }

    get meiliSearchToken(): string {
        return this.meta?.meilisearchToken ?? '';
    }

    get emailFromName(): string {
        return this.attributes?.emailFromName;
    }

    get emailReplyTo(): string {
        return this.attributes?.emailReplyTo;
    }

    get replyToEmail(): string {
        return this.meta?.replyToEmail ?? '';
    }

    get copyEmail(): string {
        return this.meta?.copyEmail ?? '';
    }

    get websocket(): TenantWebsocketMeta {
        return this.meta?.websocket ?? {};
    }

    get websocketChannel(): string | null {
        return this.websocket?.channel ?? null;
    }

    get mode(): Mode {
        return this.attributes?.mode;
    }

    get usersSpots(): number {
        return this.attributes?.usersSpots;
    }

    get paidUsersSpots(): number {
        return this.attributes?.paidUsersSpots;
    }

    get freeUsersSpots(): number {
        return this.attributes?.freeUsersSpots;
    }

    get isInTestMode(): boolean {
        return this.mode === MODE.TEST;
    }

    get isNotInTestMode(): boolean {
        return !this.isInTestMode;
    }

    get hasAcceptedTermsOfService(): boolean {
        return this.meta?.termsOfService?.accepted ?? true;
    }

    get hasNotAcceptedTermsOfService(): boolean {
        return !this.hasAcceptedTermsOfService;
    }

    shouldAcceptTermsOfService(user: User): boolean {
        if (this.hasAcceptedTermsOfService) {
            return false;
        }

        return user.role === 'super-admin';
    }

    getDefaultExpenseVatRate(): number {
        if (this.isFrench()) {
            return 20;
        }

        return 21;
    }

    get fromV1(): boolean {
        return this.attributes.fromV1;
    }

    get lastInvoice(): { id: string; createdAt: moment.Moment } | null {
        const lastInvoice: { id: string; createdAt: string } | null = this.attributes.lastInvoice;

        if (!lastInvoice) return null;

        const { createdAt, id } = lastInvoice;

        return {
            id,
            createdAt: moment(createdAt),
        };
    }

    get internalStatus(): TenantInternalStatus | null {
        return this.attributes.internalStatus;
    }

    get activatedAt(): moment.Moment | null {
        const activatedAt: string | null = this.attributes.activatedAt;

        return activatedAt ? moment(activatedAt) : null;
    }

    get satisfaction(): {
        date: moment.Moment;
        value: number;
    } | null {
        const satisfaction: { date: string; value: number } | null = this.attributes.satisfaction;

        if (!satisfaction) return null;

        const { date, value } = satisfaction;

        return {
            value,
            date: moment(date),
        };
    }
}
